<template>
  <div>
    <v-row class="mt-6" justify="center">
      <v-card elevation="3" class="pa-3" width="400">
        <h2 class="success white--text text-center mb-3">LOGIN</h2>

        <v-text-field
          prepend-inner-icon="mdi-account-circle"
          label="Username"
          outlined
          dense
        >
        </v-text-field>
        <v-text-field
          prepend-inner-icon="mdi-lock"
          label="Password"
          outlined
          dense
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
        >
        </v-text-field>
        <router-link to="" class="text-decoration-none"
          ><p class="error--text text-center">forgot password</p></router-link
        >
        <v-divider class="ma-2"></v-divider>
        <v-row justify="center" class="mt-3">
          <v-btn :to="{ name: 'Dashboard' }" color="success">
            Login
          </v-btn>
        </v-row>
      </v-card>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      showPassword: false
    };
  }
};
</script>
